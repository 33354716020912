import React from 'react';
import './Learn-More.css';

const LearnMore = () => {
    return (
        <div className='learnMore-wrapper'>
            <div className='in-development'>Hello! Our product is currenty under development and will be added to the website very soon. <br></br><br></br>If you have and questions or want to learn more, please feel free to reach out to us using the contact form! </div>
        </div>
    );
};

export default LearnMore;
