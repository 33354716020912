import React from 'react';
import './Privacy-Policy.css';

const PrivacyPolicy = () => {
    return (
        <div>
            Hello, Privacy-Policy.
        </div>
    );
};

export default PrivacyPolicy;
